import request from './config'
// 获取用户薪资
export function getUserSalary (params) {
  return request.get('/salary', { params })
}

// 设置利率
export function setSalaryRate (id, rate) {
  return request.put(`/profit/salary/${id}?rate=${rate}`)
}

// 同步薪资
export function syncSalary (month) {
  return request.get(`/profit/salary?month=${month}`)
}
