<template>
  <div class="data-sync box-shadow-light-grey border-radius-6 padding-20">
    <div class="data-sync-header">
      <span style="margin-right: 10px">平台公众号</span>
      <el-select
        v-model="accountChannelId"
        placeholder="请选择公众号"
        filterable
        clearable
      >
        <el-option
          v-for="item in channelList"
          :label="item.channelName"
          :value="item.id"
          :key="item.id"
        >
          <span style="float: left">{{ item.channelName }}</span>
          <span style="float: right; margin-left: 15px; font-size: 13px"
            >{{ item.platformStr }} - {{ item.uname }}</span
          >
        </el-option>
      </el-select>
    </div>
    <div class="data-sync-container">
      <div class="container-part">
        <h3>充值回本</h3>
        <div class="sync-date">
          <el-form label-width="80px">
            <el-form-item label="投放日期">
              <el-date-picker
                placeholder="选择日期"
                v-model="syncPutDate"
                type="date"
                :picker-options="{
                  disabledDate(time) {
                    return time > Date.now() - 1000 * 24 * 3600;
                  },
                }"
                :clearable="false"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="充值日期">
              <el-date-picker
                v-model="syncRechargeDate"
                type="date"
                :picker-options="{
                  disabledDate(time) {
                    return time > Date.now() - 1000 * 24 * 3600;
                  },
                }"
                placeholder="选择日期"
                :clearable="false"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button
                v-if="!isAlreadyPut"
                type="primary"
                plain
                style="margin-left: 10px"
                @click="syncPutData"
                >同步</el-button
              >
              <el-button v-else type="info" plain style="margin-left: 10px"
                >已同步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="container-part">
        <h3>订单数据</h3>
        <div class="sync-date">
          <el-form label-width="80px">
            <el-form-item label="同步日期">
              <el-date-picker
                v-model="syncData"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                :default-time="['00:00:00', '23:59:59']"
                @change="handleDataPickerChange"
              >
              </el-date-picker>
              <el-button
                v-if="!isAlreadyOrder"
                type="primary"
                plain
                style="margin-left: 10px"
                @click="syncOrderData"
                >同步</el-button
              >
              <el-button v-else type="info" plain style="margin-left: 10px"
                >已同步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="container-part">
        <h3>小说成本</h3>
        <div class="sync-date">
          <el-form label-width="80px">
            <el-form-item label="投放日期">
              <el-date-picker
                placeholder="选择日期"
                v-model="novelCost"
                type="date"
                :picker-options="{
                  disabledDate(time) {
                    return time > Date.now() - 1000 * 24 * 3600;
                  },
                }"
                :clearable="false"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button
                v-if="!isAlreadyNovelCost"
                type="primary"
                plain
                style="margin-left: 10px"
                @click="syncNovelCostData"
                >同步</el-button
              >
              <el-button v-else type="info" plain style="margin-left: 10px"
                >已同步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="container-part">
        <h3>薪资同步</h3>
        <div class="sync-date">
          <el-form label-width="80px">
            <el-form-item label="同步日期">
              <el-date-picker
                v-model="salaryDate"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
                :clearable="false"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <el-button
                v-if="!isAlreadySalary"
                type="primary"
                plain
                style="margin-left: 10px"
                @click="syncSalaryData"
                >同步</el-button
              >
              <el-button v-else type="info" plain style="margin-left: 10px"
                >已同步</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { syncSalary } from "@/api/salary";
import { syncOrder, syncRecharge, syncNovelCost } from "@/api/account";
export default {
  name: "dataSync",
  data() {
    return {
      platform: 0,
      accountChannelId: null, // 公众号id
      channelList: [],
      syncPutDate: null,
      syncRechargeDate: null,
      isAlreadyPut: false,
      isAlreadyOrder: false,
      isAlreadyNovelCost: false,
      isAlreadySalary: false,
      syncData: null, // 订单数据同步日期
      salaryDate: null, // 薪资同步日期
      novelCost: null, // 小说成本 日期
      platformList: [
        {
          id: 0,
          name: "掌中云",
        },
        {
          id: 1,
          name: "阅文",
        },
        {
          id: 3,
          name: "阳光书城",
        },
        {
          id: 4,
          name: "悠书阁",
        },
        {
          id: 6,
          name: "七悦",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          const month = new Date().getMonth();
          return (
            time < new Date("2020-06").getTime() ||
            time > new Date(`${new Date().getFullYear()}-${month}`).getTime()
          );
        },
      },
    };
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  watch: {
    platform: {
      handler(newV) {
        this.initChannelList();
      },
      immediate: true,
    },
  },
  methods: {
    initChannelList() {
      this.channelList = JSON.parse(JSON.stringify(this.officialList));
    },
    syncPutData() {
      if (!this.accountChannelId) {
        this.$message({
          showClose: true,
          message: "请选择公众号!",
          type: "error",
        });
        return;
      }
      if (!this.syncPutDate) {
        this.$message({
          showClose: true,
          message: "请选择投放日期",
          type: "error",
        });
        return;
      }
      if (!this.syncRechargeDate) {
        this.$message({
          showClose: true,
          message: "请选择充值日期",
          type: "error",
        });
        return;
      }
      syncRecharge(this.accountChannelId, {
        putDate: this.syncPutDate,
        rechargeDate: this.syncRechargeDate,
      }).then(() => {
        this.$message.success("同步成功!");
        this.isAlreadyPut = true;
        setTimeout(() => {
          this.isAlreadyPut = false;
        }, 2000);
      });
    },
    syncOrderData() {
      if (!this.accountChannelId) {
        this.$message({
          showClose: true,
          message: "请选择公众号!",
          type: "error",
        });
        return;
      }
      if (!this.syncData) {
        this.$message({
          showClose: true,
          message: "请选择同步日期!",
          type: "error",
        });
        return;
      }
      syncOrder(this.accountChannelId, {
        beginDate: this.syncData[0],
        endDate: this.syncData[1],
      }).then(() => {
        this.$message.success("同步成功！");
        this.isAlreadyOrder = true;
        setTimeout(() => {
          this.isAlreadyOrder = false;
        }, 2000);
      });
    },
    syncNovelCostData() {
      if (!this.accountChannelId) {
        this.$message({
          showClose: true,
          message: "请选择公众号!",
          type: "error",
        });
        return;
      }
      if (!this.novelCost) {
        this.$message({
          showClose: true,
          message: "请选择投放日期!",
          type: "error",
        });
        return;
      }
      syncNovelCost(this.accountChannelId, {
        putDate: this.novelCost,
      }).then(() => {
        this.$message.success("同步成功!");
        this.isAlreadyNovelCost = true;
        setTimeout(() => {
          this.isAlreadyNovelCost = false;
        }, 2000);
      });
    },
    syncSalaryData() {
      if (!this.salaryDate) {
        this.$message({
          showClose: true,
          message: "请选择同步日期!",
          type: "error",
        });
        return;
      }
      syncSalary(this.salaryDate).then(() => {
        this.$message.success("同步成功!");
        this.isAlreadySalary = true;
        setTimeout(() => {
          this.isAlreadySalary = false;
        }, 2000);
      });
    },
    handleDataPickerChange(date) {},
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
};
</script>
<style lang="scss" scoped>
// .el-date-editor.el-input {
//   width: 350px !important;
// }

/deep/ .el-form-item__label {
  text-align: left;
}
/deep/ .data-sync-container{
  font-size:16px !important;
}
.data-sync {
  box-sizing: border-box;
  border-radius: 6px;
  background:#fff;
  height:calc(100vh - 168px);
  .data-sync-header {
    // padding: 20px 40px;

  }
  .data-sync-container {
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-wrap: wrap;
    margin-top:40px;
    .container-part {
      // padding: 40px;
      margin:20px 0;
      width:49%;
      h3 {
        font-size:16px;
        font-weight: 400;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
